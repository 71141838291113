import React from 'react';
import '../styles/about.css';
import youngNico from '../assets/images/young-nico.webp';
import todayNico from '../assets/images/nico-now.webp';
import portrait from '../assets/images/nicolas-portrait.webp';

// slideshow
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import brevetProf from '../assets/images/brevet-prof.webp';
import nationalBrev from '../assets/images/national-brevet.webp';
import aptitudeProf from '../assets/images/aptitude-prof.webp';
import nicoSev from '../assets/images/nicolas-17.webp';
import apres from '../assets/images/apres-brevet-prof.webp';
import avant from '../assets/images/avant-brevet-prof.webp';

function About() {
    const fadeImages = [
        {
            src: nationalBrev,
            caption: 'Diplôme national du brevet'
          },
        {
          src: aptitudeProf,
          caption: "Mon Certificat d'aptitude professionel (CAP)"
        },
        {
          src: nicoSev,
          caption: 'Moi en train de coiffer après mon CAP'
        },
        {
          src: brevetProf,
          caption: 'Mon Brevet professionel'
        },
        {
            src: avant,
            caption: "La photo d'avant de mon modèle pour mon épreuve"
          },
          {
            src: apres,
            caption: "La photo d'après de mon modèle pour mon épreuve"
          },
      ];

    return(
        <div className='about-div'>
            <h2 className='about-title'>À propos de Nicolas Murati</h2>
            <div className='portrait-div'>
                <img 
                    src={portrait}
                    alt='Portrait of Nicolas Murati at Villefranche-Sur-Mer.'
                    className='portrait'
                />
            
              <p className='about-paragraph' lang='fr'>
                  Bonjour! Je suis Nicolas Murati et je suis dans la coiffure depuis mes 15 ans. 
                  Je suis diplômé d'un CAP et Brevet Professionel. Je suis situé à Nice et je me déplace en voiture avec
                  tout mon matériel dans Nice et ses alentours pour vous coiffer chez-vous!
              </p>
            </div>
            <div className='more-info'>
              <figure className='young-portrait'>
                  <img
                      src={youngNico}
                      alt='Nicolas at 15 styling hair'
                      className='young-img'
                  />
                  <img
                      src={todayNico}
                      alt='Nicolas styling hair in 2023'
                      className='young-img'
                  />
                  <figcaption lang='fr'>Moi à 15 ans et aujourd'hui en train de coiffer.</figcaption>
              </figure>

              <p className='about-paragraph' lang='fr'>
                  Mon but est de vous mettre à l'aise et que vous soyez satisfait du résultat. Avant le rendez-vous
                  à votre domicile, on fera un diagnostic par téléphone pour se mettre d'accord avec les services et les
                  tarifs qui changent selon votre zone géographique. Vous pouvez <a href='/contacter'>me contacter ici</a> avec
                  un formulaire ou vous pouvez m'envoyer un message à mon email: <a href='mailto:muratinicolas0@gmail.com'>muratinicolas0@gmail.com</a>.
              </p>
            </div>

            <h3 className='gallery-title'>Mon Histoire en photos</h3>

    <div className="slide-container">
      <Fade>
        {fadeImages.map((fadeImage, index) => (
          <div key={index} className='image-gallery-div'>
            <img style={{ width: '50%' }} src={fadeImage.src} alt={fadeImage.caption}/>
            <h2 class='fade-caption'>{fadeImage.caption}</h2>
          </div>
        ))}
      </Fade>
    </div>
  </div>
    );
};

export default About;