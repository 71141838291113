import React from 'react';
import '../styles/contact.css';
import {useForm, ValidationError} from '@formspree/react';
import hairdryer from '../assets/images/hairdryer.webp';

function Contact() {

    const [state, handleSubmit] = useForm('mbjedrqq');

    if (state.succeeded) {
        return (
            <div className='success-message'>
                <p lang='fr'>
                    Merci pour votre message! Je vous répondrai dès que possible.
                    Pour envoyer un autre message, cliquez sur <a href='/contacter'>Me Contacter encore</a> 
                    ou cliquez ici pour retourner à <a href='/'>l'Acceuil</a>. 
                </p>
            </div>
        )
    }
    return (
        <div className='content-div'>
            <h2 lang='fr' className='contact-title'>Me Contacter</h2>
            <div className='contact-dryer'>
                <div className='contact-div'>
                    <h3>Nicolas Murati</h3>
                    <div className='link-div'>
                        <a href='tel:+33 7 83 67 95 36' target='_blank' rel='noreferrer'>07 83 67 95 36</a>
                        <br />
                        <br />
                        <a href='mailto:muratinicolas0@gmail.com' target='_blank' rel='noreferrer'>muratinicolas0@gmail.com</a>
                        <br />
                        <br />
                    </div>
                </div>
                <img 
                    src={hairdryer}
                    alt='Hair dryer graphic'
                    className='hairdryer'
                />
            </div>

            <form className='contact-form' onSubmit={handleSubmit}>
                <div className='form-div'>
                    <label lang='fr' htmlFor='name'>Votre prénom et nom</label>
                    <input id='name' type='text' name='name'/>
                </div>
                <div className='form-div'>
                    <label lang='fr' htmlFor='email'>Votre e-mail</label>
                    <input id='email' type='email' name='email'/>
                </div>
                <div className='form-div'>
                    <label lang='fr' htmlFor='message'>Votre message</label>
                    <textarea className='textarea' name='message' rows='10'/>
                </div>
                <ValidationError
                    prefix='Message'
                    field='message'
                    errors={state.errors}
                />
                <div>
                    <button className='submit-button' lang='fr' type='submit' disabled={state.submitting}>
                        Envoyer
                    </button>
                </div>
            </form>
        </div>
    )
};

export default Contact;