// from dependencies
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// styling and images
import './style.css';
import NicolasLogo from '../../assets/images/chez-nicolas-logo.webp';
import ClayLogo from '../../assets/images/clays-logo.webp';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {

    return(
        <footer>
            {/*social media / Chez Nicolas logo / contact */}
            <div className='footer-info'>
                <a href='https://www.facebook.com/profile.php?id=100089825322864' target='_blank' rel='no-referrer'>
                    <FontAwesomeIcon className='footer-icon' icon={faFacebook}/>
                </a>
                <a href='https://www.instagram.com/nico_hairdresser/' rel='noreferrer' target='_blank'>
                    <FontAwesomeIcon className='footer-icon' icon={faInstagram}/>
                </a>
                <img                    
                    src={NicolasLogo}
                    alt='Chez Nicolas Logo'
                    className='footer-logo'
                />
                <a href='tel:+33 7 83 67 95 36'>
                    <FontAwesomeIcon className='footer-icon' icon={faPhone}/>
                </a>
                <a href='mailto:muratinicolas0@gmail.com'>
                    <FontAwesomeIcon className='footer-icon' icon={faPaperPlane}/>
                </a>
            </div>

            {/* Web developer information */}
            <div className='site-creator'>
                <img
                    src={ClayLogo}
                    alt="Clay's Creative Solutioins Logo"
                    className='creator-logo'
                />
                <p className='copyright'>©2023 Clay's Creative Solutions</p>
            </div>

            <div className='site-creator-contact'>
                <a className='creator-site' href='www.clayscreativesolutions.com' target='_'>www.clayscreativesolutions.com</a>
                <a className='creator-email' href='mailto: clayscreativesolutions@gmail.com'>clayscreativesolutions@gmail.com</a>
            </div>
        </footer>
    );
};

export default Footer;