// import installed dependencies
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import styling
import '../styles/home.css';

// images
import websiteBanner from '../assets/images/website-banner.webp';
import photoGallery from '../assets/images/photo-gallery.webp';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { faCarSide } from '@fortawesome/free-solid-svg-icons';

function Home() {

    return (
        <>
            <img
                    src={websiteBanner}
                    alt='Le logo de Chez Nicolas'
                    className='site-banner'
            />
            <div className='large-home'>
                <div className='gallery-div'>
                    <img
                        src={photoGallery}
                        alt='Les personnes coifées par Nicolas Murati'
                        className='photo-gallery'
                    />
                </div>
                <div className='hairdresser-home-title'>
                    <h2 className='tagline' lang='fr'>Nicolas Murati: votre coiffeur à domicile</h2>
                    <div className='home-icons'>
                    <FontAwesomeIcon className='home-icon' icon={faCarSide}/>
                        <FontAwesomeIcon className='home-icon' icon={faHouse}/>
                    </div>
                </div>
            </div>
            <p className='home-intro' lang='fr'>Bonjour, moi c'est Nicolas ! Je suis situé sur Nice et je me déplace en voiture avec tout mon matériel dans Nice et ses alentours!
                N'hésitez pas à jeter un œil à mes <a href='/services'>Services et tarifs</a> pour une idée de mes transformations. 
                Vous trouverez toutes les manières de me contacter sur <a href='/contacter'>Me Contacter</a>. 
            </p>
        </>
    );
};


export default Home;