// import installed dependencies
import React from 'react';

// import styling
import './style.css';
import Title from '../../assets/images/website-title.webp';


function Header() {

    function hideTitle() {
       const title = document.querySelector('.title');

       if (document.location.href === 'http://cheznicolas.com/') {
        title.style.display = 'none';
       } else {
        title.style.display = 'flex';
       }

    };

    return (
        <>
           <header>
                <div className='title-div'>
                    <img 
                        src={Title}
                        alt="Chez Nicolas Logo"
                        className='title'
                        onLoad={hideTitle}
                    />
                    <h1 className='website-title'>Chez Nicolas: Coiffeur</h1>
                </div>
           </header>
        </>
    );
};


export default Header;