// import burger menu
import { slide as Menu } from 'react-burger-menu';

// styling
import './style.css';

function Navbar() {
    return (
        <Menu>
            <a className='menu-item' href='/'>
                Accueil
            </a>
            <a className='menu-item' href='/services'>
                Services et Tarifs
            </a>
            {/* <a className='menu-item' href='/galerie'>
                Galerie des Photos
            </a> */}
            <a className='menu-item' href='/moi'>
                Qui est Nicolas?
            </a>
            <a className='menu-item' href='/contacter'>
                Me Contacter
            </a>
        </Menu>
    )
};

export default Navbar;