import React from 'react';
import '../styles/services.css';
import cutBundle from '../assets/images/cut-bundle.webp';
import colorBundle from '../assets/images/apres-brevet-prof.webp';
import tonBundle from '../assets/images/ton-bundle.webp';
import balayageBundle from '../assets/images/balayage-ombre.webp';
import wholeBundle from '../assets/images/meches-whole.webp';
import halfBundle from '../assets/images/meches-half.webp';
import flashBundle from '../assets/images/meches-flash.webp';

function Services() {
    return(
        <>
            <h2 lang='fr' className="service-title">Les services et leurs tarifs</h2>
            <p lang='fr' className='service-description'>Les prix sont variables en fonction de la zone géographique. Un diagnostic se fera par téléphone avant votre rendez-vous pour se mettre d'accord avec les services.
                N'hésitez pas à m'envoyer une photo de vos cheveux et une photo de ce que vous désirez en avance. Quand je suis chez-vous, si je pense que je n'ai pas tout ce qu'il faut pour
                vous coiffer comme vous voulez, je ferai un diagnostic plus approfondie et je reviendrai dès que possible avec les matériaux.
            </p>
        
            <h3 lang='fr' className='chart-title'>Les services individuelles</h3>
        
            <div className='table-div'>
                <table className='service-table'>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >La coupe enfant</td>
                        <td className='table-data' lang='fr' >10€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >La coupe homme</td>
                        <td className='table-data' lang='fr' >15€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >La coupe femme</td>
                        <td className='table-data' lang='fr' >20€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >La coloration</td>
                        <td className='table-data' lang='fr' >30€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Ton sur ton</td>
                        <td className='table-data' lang='fr' >20€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Patine</td>
                        <td className='table-data' lang='fr' >10€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Balayage et ombré</td>
                        <td className='table-data' lang='fr' >50€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Mèches (tête entière)</td>
                        <td className='table-data' lang='fr' >70€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Mèches (moitié de tête)</td>
                        <td className='table-data' lang='fr' >40€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Mèches (flash)</td>
                        <td className='table-data' lang='fr' >25€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Le brushing</td>
                        <td className='table-data' lang='fr' >20€</td>
                    </tr>
                    <tr className='underline-row'>
                        <td className='table-data' lang='fr' >Séchage naturel (diffuseur)</td>
                        <td className='table-data' lang='fr' >10€</td>
                    </tr>
                </table>
            </div>

            <h3 lang='fr' className='chart-title'>Les forfaits</h3>
        
            <div className='bundle-table-div'>
                <div className='table-div'>  
                    <img
                        src={cutBundle}
                        alt="Une coupe de cheveux faite par Nicolas"
                        className='bundle-photo'
                    />
                    <table className='service-table'>
                        <div className='collapsible-div'>
                
                            {/* <Collapsible className='additional-info' trigger='Les forfaits coupe' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits coupe</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >La coupe avec le brushing</td>
                                    <td className='table-data' lang='fr' >35€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >La coupe avec séchage naturel</td>
                                    <td className='table-data' lang='fr' >30€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >La coupe avec taille de barbe</td>
                                    <td className='table-data' lang='fr' >25€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                </div>

                <div className='table-div'>    
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits coloration' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits coloration</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Coloration brushing</td>
                                    <td className='table-data' lang='fr' >50€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Colaration, coupe et brushing</td>
                                    <td className='table-data' lang='fr' >60€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Coloration, coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >55€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Coloration et séchage naturel</td>
                                    <td className='table-data' lang='fr' >45€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                    <img 
                        src={colorBundle}
                        alt='Une coloration faite par Nicolas'
                        className='bundle-photo'
                    />
                </div>

                <div className='table-div'>  
                    <img
                        src={tonBundle}
                        alt="Un ton sur ton fait par Nicolas"
                        className='bundle-photo'
                    />
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits ton sur ton' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits ton sur ton</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Ton sur ton avec coupe et brushing</td>
                                    <td className='table-data' lang='fr' >50€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Ton sur ton avec coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >45€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Ton sur ton avec brushing</td>
                                    <td className='table-data' lang='fr' >40€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Ton sur ton avec séchage naturel</td>
                                    <td className='table-data' lang='fr' >35€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                </div>

            <div className='bundle-table-div'>
                <div className='table-div'>  
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits balayage ou ombré' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits balayage ou ombré</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Balayage ou ombré + patine avec coupe et brushing</td>
                                    <td className='table-data' lang='fr' >80€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Balayage ou ombré + patine avec coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >75€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Balayge ou ombré + patine avec brushing</td>
                                    <td className='table-data' lang='fr' >70€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Balayage ou ombré + patine avec séchaage naturel</td>
                                    <td className='table-data' lang='fr' >65€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                    <img
                        src={balayageBundle}
                        alt="Balayage fait par Nicolas"
                        className='bundle-photo'
                    />
                </div>
            </div>

            <div className='bundle-table-div'>
                <div className='table-div'>  
                    <img
                        src={wholeBundle}
                        alt="Mèches de tête entière faites par Nicolas"
                        className='bundle-photo'
                    />
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits mèches tête entière' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits mèches tête entière</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête entière + patine avec coupe et brushing</td>
                                    <td className='table-data' lang='fr' >115€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' > Mèches tête entière + patine avec coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >110€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête entière + patine avec brushing</td>
                                    <td className='table-data' lang='fr' >105€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête entière + patine avec séchage naturel</td>
                                    <td className='table-data' lang='fr' >100€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                </div>
            </div>
            
            <div className='bundle-table-div'>
                <div className='table-div'>  
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits mèches demie tête' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits mèches demie tête</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches demie tête + patine avec coupe et brushing</td>
                                    <td className='table-data' lang='fr' >95€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' > Mèches demie tête  + patine avec coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >90€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches demie tête + patine avec brushing</td>
                                    <td className='table-data' lang='fr' >85€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches demie tête + patine avec séchage naturel</td>
                                    <td className='table-data' lang='fr' >80€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                    <img
                        src={halfBundle}
                        alt="Mèches de demie tête faites par Nicolas"
                        className='bundle-photo'
                    />
                </div>
            </div>
            
            <div className='bundle-table-div'>
                <div className='table-div'>
                    <img
                        src={flashBundle}
                        alt="Mèches flash faites par Nicolas"
                        className='bundle-photo'
                    />  
                    <table className='service-table'>
                        <div className='collapsible-div'>
                            {/* <Collapsible className='additional-info' trigger='Les forfaits mèches flash' lang='fr'> */}
                                <tr>
                                    <th className='table-header'>Les forfaits mèches flash</th>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête flash + patine avec coupe et brushing</td>
                                    <td className='table-data' lang='fr' >75€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' > Mèches tête flash + patine avec coupe et séchage naturel</td>
                                    <td className='table-data' lang='fr' >70€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête flash + patine avec brushing</td>
                                    <td className='table-data' lang='fr' >65€</td>
                                </tr>
                                <tr className='underline-row'>
                                    <td className='table-data' lang='fr' >Mèches tête flash + patine avec séchage naturel</td>
                                    <td className='table-data' lang='fr' >60€</td>
                                </tr>
                            {/* </Collapsible> */}
                        </div>
                    </table>
                </div>
            </div>
        </div>
    </>
    );
};

export default Services;