// import installed dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// styling
import './App.css';


// import components
import Header from './components/header';
import Navigation from './components/nav';
import Footer from './components/footer';

// import pages
import About from './pages/about.js';
import Home from './pages/home.js';
import Services from './pages/services.js';
import Contact from './pages/contact.js';



function App() {
  return (
    <Router>
      <>
        <Header />
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/moi' element={<About />} />
          <Route path='/contacter' element={<Contact />} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
